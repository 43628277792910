export const getShortlinkFromURL = () => {
  return window.location.pathname.substring(1);
};

export const getShortlinkFromStorage = () => {
  try {
    const item = sessionStorage.getItem("redirect");
    if (item) {
      sessionStorage.removeItem("redirect");
      const { value, createdAt } = JSON.parse(item);
      if (Date.now() - createdAt <= 20 * 1000 /* 20 seconds */) {
        return value;
      }
      console.log(`Found old redirect entry (${value}), ignored.`);
    }
    return window.location.pathname.substring(1);
  } catch (e) {}
};

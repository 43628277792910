// @ts-check
import { render, Component } from "preact";
import { html } from "htm/preact";
import { Stitch, GoogleRedirectCredential } from "mongodb-stitch-browser-core";
import { RemoteMongoClient } from "mongodb-stitch-browser-services-mongodb-remote";
import cx from "classnames";
import logoSrc from "./short-link-logo.png";
import { getShortlinkFromStorage } from "./shared";
import SvgHero from "./hero";

const client = Stitch.initializeDefaultAppClient("mongodb-link-uaesj");

class PublicButton extends Component {
  togglePublicallyAccessible = () => {
    this.props.setPublicallyAccessible(!this.props.isPublicallyAccessible);
  };

  render({ className, isPublicallyAccessible }) {
    let classes = cx(
      "px-3 py-1 focus:outline-none font-normal text-sm border border-gray-400 rounded group",
      "text-gray-800 hover:text-blue-600 hover:shadow hover:border-gray-900", //isPublicallyAccessible ? "text-blue-600" :
      className
    );

    return html`
      <button
        style="background-image: linear-gradient(-180deg, #fff, #f2f3f3);"
        class=${classes}
        onClick=${this.togglePublicallyAccessible}
      >
        ${isPublicallyAccessible
          ? html`
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 496 512"
                class="h-4 w-4"
              >
                <path
                  fill="currentColor"
                  d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm-32 50.8v11.3c0 11.9-12.5 19.6-23.2 14.3l-24-12c14.9-6.4 30.7-10.9 47.2-13.6zm32 369.8V456c-110.3 0-200-89.7-200-200 0-29.1 6.4-56.7 17.6-81.7 9.9 14.7 25.2 37.4 34.6 51.1 5.2 7.6 11.2 14.6 18.1 20.7l.8.7c9.5 8.6 20.2 16 31.6 21.8 14 7 34.4 18.2 48.8 26.1 10.2 5.6 16.5 16.3 16.5 28v32c0 8.5 3.4 16.6 9.4 22.6 15 15.1 24.3 38.7 22.6 51.3zm42.7 22.7l17.4-46.9c2-5.5 3.3-11.2 4.8-16.9 1.1-4 3.2-7.7 6.2-10.7l11.3-11.3c8.8-8.7 13.7-20.6 13.7-33 0-8.1-3.2-15.9-8.9-21.6l-13.7-13.7c-6-6-14.1-9.4-22.6-9.4H232c-9.4-4.7-21.5-32-32-32s-20.9-2.5-30.3-7.2l-11.1-5.5c-4-2-6.6-6.2-6.6-10.7 0-5.1 3.3-9.7 8.2-11.3l31.2-10.4c5.4-1.8 11.3-.6 15.5 3.1l9.3 8.1c1.5 1.3 3.3 2 5.2 2h5.6c6 0 9.8-6.3 7.2-11.6l-15.6-31.2c-1.6-3.1-.9-6.9 1.6-9.3l9.9-9.6c1.5-1.5 3.5-2.3 5.6-2.3h9c2.1 0 4.2-.8 5.7-2.3l8-8c3.1-3.1 3.1-8.2 0-11.3l-4.7-4.7c-3.1-3.1-3.1-8.2 0-11.3L264 112l4.7-4.7c6.2-6.2 6.2-16.4 0-22.6l-28.3-28.3c2.5-.1 5-.4 7.6-.4 78.2 0 145.8 45.2 178.7 110.7l-13 6.5c-3.7 1.9-6.9 4.7-9.2 8.1l-19.6 29.4c-5.4 8.1-5.4 18.6 0 26.6l18 27c3.3 5 8.4 8.5 14.1 10l29.2 7.3c-10.8 84-73.9 151.9-155.5 169.7z"
                ></path>
              </svg>
            `
          : html`
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                class="h-4 w-4"
              >
                <path
                  fill="currentColor"
                  d="M496 432a32 32 0 1 0-32-32 32 32 0 0 0 32 32zm-192 32H48v-25.6a86.55 86.55 0 0 1 86.4-86.4c14.6 0 38.3 16 89.6 16 42.3 0 69.5-11.7 80-14.4V320a83.12 83.12 0 0 1 1.5-15.1c-26.2 2.9-40 15.1-81.5 15.1-47.1 0-60.8-16-89.6-16A134.43 134.43 0 0 0 0 438.4V464a48 48 0 0 0 48 48h262.8c-7.9-18-6.8-30.7-6.8-48zm304-176h-32v-48a80 80 0 0 0-160 0v48h-32a32 32 0 0 0-32 32v160a32 32 0 0 0 32 32h224a32 32 0 0 0 32-32V320a32 32 0 0 0-32-32zm-144-48a32 32 0 0 1 64 0v48h-64zm128 224H400V336h192zM224 0a144 144 0 1 0 144 144A144 144 0 0 0 224 0zm0 240a96 96 0 1 1 96-96 96.15 96.15 0 0 1-96 96z"
                ></path>
              </svg>
            `}
      </button>
    `;
  }
}

class ConfirmDelete extends Component {
  timeoutId = null;
  state = { showWarning: false };
  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  handleDeleteClick = () => {
    if (!this.state.showWarning) {
      this.setState({ showWarning: true });
      setTimeout(() => {
        this.setState({ showWarning: false });
      }, 5000);
    } else {
      clearTimeout(this.timeoutId);
      this.props.onDelete();
    }
  };

  render({}, { showWarning }) {
    return html`
      <button
        style=${showWarning
          ? "background-image: linear-gradient(-180deg,#ef4c4c,#db1414);"
          : "background-image: linear-gradient(-180deg, #fff, #f2f3f3);"}
        class=${showWarning
          ? "px-3 py-1 focus:outline-none font-normal text-sm text-gray-200 border border-red-800 rounded hover:shadow hover:border-gray-800"
          : "px-3 py-1 focus:outline-none font-normal text-sm text-gray-800 border border-gray-400 rounded hover:shadow hover:border-gray-800"}
        onClick=${this.handleDeleteClick}
      >
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          class="w-4 h-4"
        >
          <path
            fill="currentColor"
            d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
            class=""
          ></path>
        </svg>
      </button>
    `;
  }
}

class App extends Component {
  async componentDidMount() {
    const mongodb = client.getServiceClient(
      RemoteMongoClient.factory,
      "mongodb-atlas"
    );
    const db = mongodb.db("mongodb-link-db");
    this.collections = db.collection("shortlinks");
    try {
      // const changeStream = await this.collections.watch({
      //   owner: client.auth.user.id
      // });
      const items = await this.collections
        .find({ owner: client.auth.user.id })
        .toArray();
      this.setState({ items, isLoading: false });
    } catch (e) {
      this.setState({ error: e, isLoading: false });
    }
  }

  onSaveShortlink = async e => {
    if (e) e.preventDefault();
    this.setState({ error: null });
    try {
      const shortlink = document.getElementById("shortlink").value;
      const path = document.getElementById("path").value;
      const { insertedId } = await this.collections.insertOne({
        shortlink,
        path,
        owner: client.auth.user.id
      });
      this.setState(prevState => {
        return {
          items: (prevState.items || []).concat({
            _id: insertedId,
            shortlink,
            path,
            owner: client.auth.user.id,
            isPublicallyAccessible: false
          })
        };
      });
    } catch (error) {
      if (/duplicate key error/i.test(error.message)) {
        this.setState({ error: { type: "DUPLICATE KEY" } });
      } else {
        this.setState({ error });
        console.log(error);
      }
    }
  };

  onTogglePublic = async (id, isPublicallyAccessible) => {
    try {
      this.setState(prevState => {
        const items = prevState.items;
        const idx = items.findIndex(i => i._id === id);
        if (idx >= 0) {
          items[idx] = {
            ...items[idx],
            isPublicallyAccessible
          };
          return { items };
        }
      });
      await this.collections.updateOne(
        { _id: id },
        { $set: { isPublicallyAccessible } }
      );
    } catch (e) {
      this.setState(prevState => {
        const items = prevState.items;
        const idx = items.findIndex(i => i._id === id);
        if (idx >= 0) {
          items[idx] = {
            ...items[idx],
            isPublicallyAccessible: !isPublicallyAccessible
          };
          return { items };
        }
      });
    }
  };

  onDelete = async id => {
    const removedItemIdx = this.state.items.findIndex(i => i._id === id);
    const removedItem = this.state.items[removedItemIdx];
    try {
      this.setState(prevState => {
        return {
          items: (prevState.items || []).filter(item => item._id !== id)
        };
      });
      await this.collections.deleteOne({
        _id: id
      });
    } catch (e) {
      this.setState(prevState => {
        const items = prevState.items.splice(removedItemIdx, 0, removedItem);
        return { items, error: { type: "BAD DELETE" } };
      });
    }
  };

  render({}, { error = null, items = [], isLoading = true }) {
    if (isLoading)
      return html`
        <div class="flex flex-col items-center justify-center h-screen">
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="h-16 w-16 fa-pulse"
            style="color: #18964c;"
          >
            <path
              fill="currentColor"
              d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
              class=""
            ></path>
          </svg>
          <div class="mt-4">Loading...</div>
        </div>
      `;
    if (error instanceof Error)
      return html`
        <div>Something went wrong... (${error.message})</div>
      `;
    return html`
      <div id="app" class="flex flex-col">
        <div
          class="bg-white border-2 border-gray-200 h-16 px-4 py-1 flex items-center"
        >
          <img src=${logoSrc} class="h-8" />
        </div>
        <div class="max-w-2xl m-auto px-4 py-6">
          <h1 class="text-3xl text-gray-900">Dashboard</h1>
          <h2 class="mt-8 uppercase font-semibold text-sm text-gray-700">
            Add new shortlink
          </h2>
          <form
            class="mt-4 flex flex-col md:flex-row"
            onSubmit=${this.onSaveShortlink}
          >
            <input
              id="shortlink"
              placeholder="shortlink (mongo)"
              required
              class="w-full px-3 py-1 outline-none text-sm border border-gray-400 rounded md:w-40 mb-2 md:mr-4"
            />
            <input
              id="path"
              placeholder="full url (https://mongodb.com)"
              required
              pattern="^https?:\/\/.*"
              class="w-full px-3 py-1 outline-none text-sm border border-gray-400 rounded mb-2 md:mr-4"
            />
            <button
              style="background-image:linear-gradient(-180deg,#13aa52,#18964c)"
              class="px-3 py-1 focus:outline-none font-normal text-sm text-white border border-green-800 rounded mb-2 hover:shadow hover:border-green-900"
            >
              Create
            </button>
          </form>
          ${error &&
            html`
              <div
                class="px-4 py-2 border border-red-300 bg-red-100 text-red-800 rounded"
              >
                ${(() => {
                  if (error.type === "DUPLICATE KEY")
                    return "This shortlink has already been taken";
                  else if (error.type === "BAD DELETE")
                    return "Failed to delete a shortlink";
                  else
                    return "Unknown error: " + JSON.stringify(error, null, 2);
                })()}
              </div>
            `}
          <h2 class="mt-8 uppercase font-semibold text-sm text-gray-700">
            Manage existing shortlinks
          </h2>
          <div class="overflow-x-scroll">
            <table class="table-fixed w-full border-collapse text-sm">
              <colgroup>
                <col span="1" class="w-1/6" />
                <col span="1" class="w-3/6" />
                <col span="1" class="w-1/6" />
              </colgroup>
              <thead>
                <tr>
                  <th class="p-2 border-b-2 text-left">Alias</th>
                  <th class="p-2 border-b-2 text-left">Full Url</th>
                  <th class="p-2 border-b-2 text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                ${items.map(
                  item => html`
                    <tr>
                      <td class="border-b p-2">${item.shortlink}</td>
                      <td class="border-b p-2 truncate">
                        <a href=${item.path}>${item.path}</a>
                      </td>
                      <td class="border-b p-2 text-right">
                        <${PublicButton}
                          className="mr-2"
                          isPublicallyAccessible=${item.isPublicallyAccessible}
                          setPublicallyAccessible=${this.onTogglePublic.bind(
                            this,
                            item._id
                          )}
                        />
                        <${ConfirmDelete}
                          onDelete=${this.onDelete.bind(this, item._id)}
                        />
                      </td>
                    </tr>
                  `
                )}
              </tbody>
            </table>
          </div>
          ${items.length === 0 &&
            html`
              <div class="flex flex-col items-center justify-center py-8">
                <${SvgHero} />
                <p class="text-center mt-4 font-light">
                  You haven't created any links yet.<br />Let's get linking!
                </p>
              </div>
            `}
        </div>
      </div>
    `;
  }
}

if (client.auth.hasRedirectResult()) {
  const shortlink = getShortlinkFromStorage();
  if (shortlink) {
    client.auth
      .handleRedirectResult()
      .then(() => {
        window.location.replace(window.location.origin + "/" + shortlink);
      })
      .catch(() => {
        window.location.replace(window.location.origin + "/404");
      });
  } else {
    client.auth.handleRedirectResult().finally(() => window.location.reload());
  }
} else if (!client.auth.isLoggedIn) {
  const credential = new GoogleRedirectCredential(window.location.origin + "/");
  client.auth.loginWithRedirect(credential);
} else {
  document.addEventListener("DOMContentLoaded", () => {
    render(
      html`
        <${App} />
      `,
      document.body,
      document.getElementById("app")
    );
  });
}
